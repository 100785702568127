<template>
  <b-container fluid id="gallery-container">
    <b-row>
      <b-col
        cols="12"
        md="6"
        xl="3"
        v-for='image in images' :key='image.id'
      >
        <transition name="fade">
          <b-img-lazy
            v-bind="mainProps"
            :src="image.url"
            class="images-g"
            fluid rounded="" alt="Product picture"
          >
          </b-img-lazy>
        </transition>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { fireStorage } from '../plugins/firebase';

export default {
  name: 'Gallery',
  data () {
    return {
      images: [],
      mainProps: {
        center: true,
        fluidGrow: true,
        blank: true,
        blankColor: '#3d3d3d'
      }
    }
  },
  async created() {
    await this.loadImages()
  },
  mounted() {
    this.$gtm.trackView('Products', '/gallery');
  },
  methods: {
    async loadImages() {
      const imagesRef = fireStorage.ref().child('Galerija')
      imagesRef.listAll()
        .then(res => {
          res.items.forEach(itemRef => {
            this.images.push({id: itemRef.name, url: ''})
          });
        })
        .then(async () => {
          for(let item of this.images) {
            item.url = await imagesRef.child(item.id).getDownloadURL()
          }
        })
        .catch(error => {
          console.log(error)
      });
    },
    async updateImgs() {
      this.images = []
      await this.loadImages()
    }
  }
}
</script>

<style scoped>
#gallery-container{
  width: 100%;
  padding: 3%;
}
.images-g{
  margin-bottom: 20px;
  height: 25vh;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media only screen and (max-width: 800px) {
    .images-g{
      width: 100%;
      height: auto;
    }
}

.fade-enter-active {
  transition: opacity 1.5s ease-in-out;
}
.fade-enter-to {
  opacity: 1;
}
.fade-enter {
  opacity: 0;
}
</style>